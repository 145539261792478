import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import messageStoreModule from '../messageStoreModule';

export default function useMessageGroupList() {
  const APARTMENT_STORE_MODULE_NAME = "message";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, messageStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
      store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    content: '',
    zaloSender: null,
    receivePhoneNumber: '',
    attachmentArray: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [

    {
      label: 'Mã',
      field: 'code',
      width: '100px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      width: '120px',
      sortable: false,
    },
    {
      label: 'Nội dung',
      field: 'content',
    },
    {
      label: 'SĐT gửi',
      field: 'senderPhone',
    },
    {
      label: 'SĐT nhận',
      field: 'receivePhoneNumber',
    },
    {
      label: 'Ngày tạo',
      field: 'createdAt',
      width: '140px'
    },

    {
      label: 'Trạng thái',
      field: 'sendStatus',
      width: '150px'
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  const automation = ref(null);
  const template = ref(null);
  const zaloAccount = ref(null);
  const sendStatus = ref(null);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchAnalytics = () => {
    store.dispatch('message/getAnalytics').then(response => {
      analytics.value = response.data;
    }).catch(error => {
      toastification.showToastError(error);
      isLoading.value = false;
    });
  };
  const fetchMessageGroups = () => {
    store
      .dispatch('message/getItems', serverParams.value)
      .then(response => {
        const { items, total } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchMessageGroups();
    fetchAnalytics();
  };
  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('message/importItem', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        const vm = getCurrentInstance().proxy;
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const resendMessage = messageId => {
    store
      .dispatch('message/resendMessage', messageId)
      .then(() => {
        fetchData();
        toastification.showToastSuccess('Hệ thống sẽ tiến hành gửi lại tin nhắn. Quá trình này có thể mất chút ít thời gian nếu số tin nhắn chờ gửi quá nhiều');
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('message/exportItem', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadTemplateFile = () => {
    store
      .dispatch('message/downloadTemplateFile', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteMessageGroups = messages => {
    store
      .dispatch('message/deleteItem', {
        ids: messages.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onSelectItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(automation, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.automationId = val.id;
    } else {
      delete filter.automationId;
    }
    updateParams({ filter });
  });
  watch(template, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.templateId = val.id;
    } else {
      delete filter.templateId;
    }
    updateParams({ filter });
  });
  watch(zaloAccount, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.zaloAccountId = val.id;
    } else {
      delete filter.zaloAccountId;
    }
    updateParams({ filter });
  });
  watch(sendStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.sendStatus = val.id;
    } else {
      delete filter.sendStatus;
    }
    updateParams({ filter });
  });

  return {
    analytics,
    item,
    template,
    zaloAccount,
    automation,
    sendStatus,
    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    fetchMessageGroups,
    deleteMessageGroups,
    onSelectItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    parseDateToString,
    downloadTemplateFile,
    resendMessage,
  };
}
