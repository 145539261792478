<template>
  <b-modal
    id="modal-message"
    ref="refModal"
    title="Tin nhắn"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="zaloSender"
              rules="required"
            >
              <select-zalo-account
                v-model="itemLocal.zaloSender"
                required
                label="SĐT gửi"
                :default-value="itemLocal.zaloSender"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="receivePhoneNumber"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  SĐT nhận <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="receivePhoneNumber"
                    v-model="itemLocal.receivePhoneNumber"
                    placeholder="0868 987 355"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="template"
              rules=""
            >
              <select-template
                v-model="itemLocal.template"
                label="Mẫu gửi tin"
                required
                :default-value="itemLocal.template"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="receivePhoneNumber"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Nội dung tin nhắn <span class="text-danger"></span>
                </template>
                <b-form-textarea
                  id="content"
                  v-model="itemLocal.content"
                  placeholder="Nội dung tin nhắn"
                  rows="3"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                Đính kèm
              </div>
            </div>
          </b-col>
          <b-col cols="12">

            <media-view
              v-model="itemLocal.attachmentArray"
              :label="null"
              :initial-value="itemLocal.attachmentArray"
              :upload-imediately="true"
              @on-delete-uploaded-file="onDeleteAttachment"
            />

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectZaloAccount from '@/views/components/SelectZaloAccount.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import useMessageModal from './useMessageModal';
import SelectTemplate from '@/views/components/SelectTemplate.vue';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    SelectZaloAccount,
    MediaView,
    SelectTemplate
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
      onDeleteAttachment,
      onOpen,
    } = useMessageModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      onDeleteAttachment,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
    };
  },
};
</script>
./useMessageModal
./useMessageModal
./useMessageModal
./useMessageModal
